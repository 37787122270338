<template>
  <div>
    <div class="container maxHeight">
      <div v-if="items.length <= 0">
        <h2 class="text-center notFoundText">
          Nenhuma Solicitação de Relatório Encontrado
        </h2>
      </div>
      <div v-else>
        <div class="row" v-if="this.sizeWindow > 767">
          <div class="col-2"><h4>Data inicial</h4></div>
          <div class="col-2"><h4>Data Final</h4></div>
          <div class="col-2"><h4>Serviço</h4></div>
          <div class="col-2"><h4>Consulta</h4></div>

          <div class="col-2"><h4>Status</h4></div>
        </div>
        <div>
          <div
            class="row containers extrator"
            v-for="item in items"
            :key="item.tag"
          >
            <div class="col-2 m-auto">
              <!-- {{item}} -->
              <h4 v-if="sizeWindow < 768" class="titleMobie">Data inicial</h4>
              <h4>
                {{
                  `${
                    item.parameters.dateInitial
                      ? formData(item.parameters.dateInitial)
                      : "Não informado"
                  }`
                }}
              </h4>
            </div>
            <div class="col-2 m-auto">
              <!-- {{item}} -->
              <h4 v-if="sizeWindow < 768" class="titleMobie">Data Final</h4>
              <h4>
                {{
                  `${
                    item.parameters.dateFinale
                      ? formData(item.parameters.dateFinale)
                      : "Não informado"
                  }`
                }}
              </h4>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <h4 v-if="sizeWindow < 768" class="titleMobie">Serviço</h4>
              <h4>
                {{
                  item.parameters.serviceName
                    ? item.parameters.serviceName
                    : "Não informado"
                }}
              </h4>
            </div>
            <div class="col-2 m-auto">
              <h4 v-if="sizeWindow < 768" class="titleMobie">Consulta</h4>
              <h4>{{ item.created }}</h4>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <h4 v-if="sizeWindow < 768" class="titleMobie">Status</h4>
              <div
                v-if="
                  item.parameters.statusServices &&
                    item.parameters.statusServices.length
                "
                class="col-md-2 form-group form-outline"
                id="tooltip"
              >
                <i
                  class="fas fa-info-circle mt-3 text-white"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="`${getTooltipStatus(item.parameters.statusServices)}`"
                >
                </i>
              </div>
              <h4 v-else>Não Informado</h4>
            </div>
            <div
              class="col-2 d-flex justify-content-around align-items-center"
              v-if="item.report.length"
            >
              <csvExportVue :dataJson="item.report" :visible="true" />
            </div>
            <div
              class="col-2 d-flex justify-content-around align-items-center"
              v-else-if="!item.isComplete"
            >
              <h4>Aguarde...</h4>
            </div>
            <div
              class="col-2 d-flex justify-content-around align-items-center"
              v-else
            >
              <button @click="error" class="bg-danger text-white btnErro">
                Ops
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import csvExportVue from "./csvExportVue.vue";
import { mapActions } from "vuex";
export default {
  components: {
    csvExportVue
  },
  props: {
    items: {
      type: [Object, Array],
      default: {}
      // require: true
    }
  },
  data() {
    return {
      status: [],
      sizeWindow: 0,
    };
  },
  methods: {
    ...mapActions(["getServiceStatus"]),

    getStatus() {
      this.getServiceStatus()
        .then(({ data }) => {
          const { result } = data;
          this.status = result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    filterTags(tagStatus) {
      const result = this.getTagsAll.filter(item => item.id == tagStatus);
      return result;
    },
    formData(item) {
      return String(item)
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("-");
    },
    enableTooltip() {
      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    getTooltipStatus(listGroups) {
      let templateToolTip = "";
      this.status
        .filter(s => listGroups.includes(s.id))
        .forEach(s => {
          templateToolTip = templateToolTip.concat(`•${s.name}\n`);
        });
      return templateToolTip;
    },
    error() {
      this.toast(
        "Não foi possível obter dados com os parâmetros informados nessa consulta",
        this.$toast.error
      );
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.enableTooltip();
    this.getStatus();
  }
};
</script>

<style scoped>
.containers {
  margin-top: 10px;
  background: #3f88c1;
  border-radius: 8px;
  height: 56px;
  transition: 0.1s ease-in-out;
}

.container h4 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.containers:first-child {
  margin-top: 20px;
}

.containers:hover {
  background: #2474b2;
  border: 1px solid #3f88c1;
}

.containers h4 {
  font-weight: 600;
  font-size: 16px;
}

.unid h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.notFoundText {
  font-weight: 600;
  margin-top: 100px;
  font-size: 16px;
  color: #98c4e6;
}

span {
  background: #ffbc02;
  border-radius: 18px;
  color: #fff;
  padding: 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  /* color: #000;
  padding: 10px 20px; */
}

.maxHeight {
  max-height: 300px;
  overflow: auto;
  margin: 0 0 76px;
}

.btnErro {
  height: 40px;
  width: 115px;
  border-radius: 5px;
  border: 2px solid;
  cursor: pointer;
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  border: none;
}
@media screen and (max-width:767px){
  .containers{
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .col-2{
    max-width: 100%;
    margin-top: 5px!important;
    margin-bottom: 5px!important;
    flex-direction: column;
  }
  .titleMobie{
    color: #ffa700!important;
  }
}
</style>
