<template>
    <div class="btns">
      <vue-json-to-csv
        v-if="dataJson != ''"
        :json-data="dataJson"
        :labels="{
          serviceName: { title: 'Serviço' },
          tenantName: { title: 'Unidade' },
          date: { title: 'Data' },
          datePayment: { title: 'Data de Finalização' },
          value: { title: 'Valor' },
          comissionFranchisee: { title: 'Comissão Unidade' },
          paymentStatus: { title: 'Status Pagamento' },
          pixTxId: { title: 'TxID Pix' },
          pixCode: { title: 'Copia/cola Pix' },
          operationStatus: { title: 'Status da Operação' },
          e2EPix: { title: 'E2E Pix' },
          operatorName: { title: 'Operador que atendeu o serviço' },
          clientName: { title: 'Nome do Cliente' },
          clientCNPJ: { title: 'CNPJ do Cliente' },
          clientCPF: { title: 'CPF do Cliente' },
          clientBirthDate: { title: 'Data de Nascimento do Cliente' },
          clientCity: { title: 'Cidade do Cliente' },
          clientState: { title: 'Estado do Cliente' },
          clientAddress: { title: 'Endereço do Cliente' },
          clientCellPhone: { title: 'Celular do Cliente' },
          clientEmail: { title: 'Email do Cliente' }
        }"
        :csv-title="'Serviços Banco do Brasil'"
        :separator="';'"
        @success="val => handleSuccess(val)"
        @error="val => handleError(val)"
      >
        <button class="btn_default">Extrair</button>
      </vue-json-to-csv>
      <button v-else class="btn_default" @click="$parent.ShowError(msg)">
        Extrair
      </button>
    </div>
  </template>
  
  <script>
  import VueJsonToCsv from "vue-json-to-csv";
  export default {
    props: ["dataJson", "msg"],
    components: {
      VueJsonToCsv
    },
    methods: {
      handleSuccess(e) {
        console.log(e);
      },
      handleError(e) {
        console.log(e, "erro");
      }
    }
  };
  </script>
  
  <style scoped>
  .btns button {
    height: 40px;
    width: 115px;
    border-radius: 5px;
    border: 2px solid;
    background-color: #a9a2a2;
    color: #fff;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 7px;
    font-weight: 500;
    color: #ffff;
    -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
    box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    font-size: 14px;
    background-color: #e0a638;
    border: none;
  }
  
  .btns button:hover {
    background-color: #f38235;
  }
  </style>
  