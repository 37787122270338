var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btns"},[(_vm.dataJson != '')?_c('vue-json-to-csv',{attrs:{"json-data":_vm.dataJson,"labels":{
      serviceName: { title: 'Serviço' },
      tenantName: { title: 'Unidade' },
      date: { title: 'Data' },
      datePayment: { title: 'Data de Finalização' },
      value: { title: 'Valor' },
      comissionMaster: { title: 'Comissão Master' },
      comissionFranchisee: { title: 'Comissão Unidade' },
      comissionBB: { title: 'Comissão Banco do Brasil' },
      paymentStatus: { title: 'Status Pagamento' },
      pixTxId: { title: 'TxID Pix' },
      pixCode: { title: 'Copia/cola Pix' },
      operationStatus: { title: 'Status da Operação' },
      e2EPix: { title: 'E2E Pix' },
      operatorName: { title: 'Operador que atendeu o serviço' },
      clientName: { title: 'Nome do Cliente' },
      clientCNPJ: { title: 'CNPJ do Cliente' },
      clientCPF: { title: 'CPF do Cliente' },
      clientBirthDate: { title: 'Data de Nascimento do Cliente' },
      clientCity: { title: 'Cidade do Cliente' },
      clientState: { title: 'Estado do Cliente' },
      clientAddress: { title: 'Endereço do Cliente' },
      clientCellPhone: { title: 'Celular do Cliente' },
      clientEmail: { title: 'Email do Cliente' }
    },"csv-title":'Serviços Banco do Brasil',"separator":';'},on:{"success":function (val) { return _vm.handleSuccess(val); },"error":function (val) { return _vm.handleError(val); }}},[_c('button',{staticClass:"btn_default"},[_vm._v("Extrair")])]):_c('button',{staticClass:"btn_default",on:{"click":function($event){return _vm.$parent.ShowError(_vm.msg)}}},[_vm._v(" Extrair ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }