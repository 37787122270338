var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btns"},[(_vm.dataJson != '')?_c('vue-json-to-csv',{attrs:{"json-data":_vm.dataJson,"labels":{
      tenantName: { title: 'Unidade' },
      monthYear: { title: 'Mês/Ano' },
      grossProfit: { title: 'Receita Bruta' },
      expense: { title: 'Despesa' },
      netProfit: { title: 'Receita Líquida' },
      ticketAverage: { title: 'Ticket Médio' },
      dailyProfit: { title: 'Média/dia' },
      goalGrossProfit: { title: 'Meta Mês' },
      hitTarget: { title: 'Meta atingida' }
    },"csv-title":'Indicadores Mensais',"separator":';'},on:{"success":function (val) { return _vm.handleSuccess(val); },"error":function (val) { return _vm.handleError(val); }}},[_c('button',{staticClass:"btn_default"},[_vm._v("Extrair")])]):_c('button',{staticClass:"btn_default",on:{"click":function($event){return _vm.$parent.ShowError(_vm.msg)}}},[_vm._v(" Extrair "+_vm._s(_vm.nome)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }